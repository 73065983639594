<template>
  <ion-header>
    <ion-toolbar color="light">
      <ion-title>{{ element.name }} {{ category.name }}</ion-title>
      <ion-buttons slot="end">
        <ion-button
          @click="close"
        >
          Close
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-textarea
      placeholder="Type your notes here"
      :autoGrow="true"
      v-model="notes"
    ></ion-textarea>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue';
import { IonTextarea, IonButtons, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, modalController } from '@ionic/vue';
import { getCategory, getElement } from '../data/taxonomy'

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonTextarea,
    IonButtons,
    IonButton,
  },
  props: {
    categoryId: {
      type: String,
    },
    elementId: {
      type: String,
    }
  },
  watch: {
    notes () {
      this.$store.state.session.taxonomy.categories[this.categoryId][this.elementId].notes = this.notes
    }
  },
  data () {
    const category = getCategory(this.categoryId)
    const element = getElement(this.categoryId, this.elementId)
    return {
      category,
      element,
      notes: this.$store.state.session.taxonomy.categories[this.categoryId][this.elementId].notes
    }
  },
  mounted () {
    //
  },
  setup () {
    return {
      close() {
        return modalController.dismiss(null, 'close')
      },
    }
  },
  computed: {
    //
  }
})
</script>

<style lang="scss" scoped>

</style>