<template>
  <ion-button
    :color="color"
    :size="size"
    :fill="fill"
    @click="showSendReportModal()"
  >
    Send Report
  </ion-button>
</template>

<script>
import { defineComponent } from 'vue'
import { useIonRouter, IonButton, modalController } from '@ionic/vue'
import SendReportModal from './SendReportModal'
import { useStore } from '../data/store'

export default defineComponent({
  components: {
    IonButton,
  },
  props: {
    color: {
      type: String,
      default: 'light',
    },
    size: {
      type: String,
      default: 'default',
    },
    fill: {
      type: String,
      default: 'solid',
    },
  },
  setup() {
    const ionRouter = useIonRouter()
    const store = useStore()

    const showSendReportModal = async () => {
      const modal = await modalController.create({
        component: SendReportModal,
      });
      modal.present()
      await modal.onWillDismiss()
    }

    return {
      showSendReportModal,
    }
  },
})
</script>