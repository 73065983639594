
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { listOutline, listSharp, archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, telescopeOutline, homeOutline, homeSharp, gridOutline, gridSharp, buildOutline, buildSharp } from 'ionicons/icons';
import { RouteRecordName } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList,
    // IonListHeader,
    IonMenu, 
    IonMenuToggle, 
    // IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  mounted () {
    //
  },
  setup() {
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
    
    const route = useRoute();
    
    return {
      labels,
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      isSelected: (names: Array<RouteRecordName | null | undefined>) =>  names.includes(route.name),
    }
  },
  computed: {
    appPages () {
      let appPages = [
        {
          title: 'All Explorations',
          names: ['Home'],
          url: '/',
          iosIcon: listOutline,
          mdIcon: listSharp,
        },
        {
          title: 'Taxonomy',
          url: '/taxonomy',
          names: ['TaxonomyHome'],
          iosIcon: gridOutline,
          mdIcon: gridSharp,
        },
        /*
        {
          title: 'Tests',
          url: '/tests',
          names: ['Tests'],
          iosIcon: buildOutline,
          mdIcon: buildSharp,
        },
         */
      ]

      return appPages
    }
  }
});
