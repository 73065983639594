<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
        </ion-buttons>
        <ion-title>Taxonomy</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense" class="ion-padding">
        <ion-toolbar>
          <ion-title size="large">The Immersive Experience Taxonomy</ion-title>
        </ion-toolbar>
      </ion-header>

      <div class="ion-padding">
        <video-player
          ref="video"
          :src="intro.video"
          :poster="intro.thumb"
        ></video-player>

        <ion-card style="text-align:center;">
          <ion-card-content>
            <h1 class="ion-padding-bottom">
              Have you created an immersive experience?
            </h1>

            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-button
                    size="large"
                    color="secondary"
                    expand="block"
                    @click.prevent="explore('share')"
                  >
                    Yes
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button
                    size="large"
                    color="secondary"
                    expand="block"
                    @click.prevent="explore('discover')"
                  >
                    Not Yet
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
            <reset-session-button
              v-if="$store.state.session.id"
            ></reset-session-button>
          </ion-card-content>
        </ion-card>

        <h2 class="subheader">
          Explore
        </h2>

        <ion-grid class="ion-padding-bottom">
          <ion-row
            v-for="(row, i) in categories"
            :key="`row_${i}`"
          >
            <ion-col
              v-for="category in row"
              :key="`category_${category.id}`"
            >
              <ion-card>
                <img
                  :alt="category.name"
                  :src="category.thumb"
                />
                <ion-card-header>
                  <ion-card-title>{{ category.name }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p class="ion-padding-bottom">
                    {{ category.description }}
                  </p>
                  <ion-button
                    color="secondary"
                    fill="outline"
                    :router-link="{
                      name: 'TaxonomyCategory',
                      params: {
                        categoryId: category.id,
                      }
                    }"
                  >
                    Learn More
                  </ion-button>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>

        <div class="ion-padding-vertical" style="text-align:center;">
          <download-sessions-button
            size="small"
            color="dark"
            fill="clear"
          ></download-sessions-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonCardHeader, IonButton, IonCardTitle, IonCardContent, IonGrid, IonRow, IonCol, IonCard, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons'
import { taxonomy } from '../../data/taxonomy'
import VideoPlayer from '../../components/VideoPlayer'
import ResetSessionButton from '../../components/ResetSessionButton'
import DownloadSessionsButton from '../../components/DownloadSessionsButton'

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonContent,
    IonTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    VideoPlayer,
    ResetSessionButton,
    DownloadSessionsButton,
  },
  setup () {
    const chunkSize = 2
    let categories = []
    for (let i = 0; i < taxonomy.categories.length; i += chunkSize) {
      categories.push(taxonomy.categories.slice(i, i + chunkSize))
    }

    return {
      intro: taxonomy.intro,
      categories,
      chevronForwardOutline,
      async explore(sessionType) {
        this.$store.dispatch(this.$store.state.session.id ? 'changeSessionType' : 'createNewSession', { sessionType })
        this.$router.push({
          name: 'TaxonomyProjectPage',
        })
      },
      ionViewWillLeave() {
        this.$refs.video.pause()
      },
    }
  },
  computed: {
    sessionId () {
      return this.$store.state.session.id
    }
  }
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 820px) {
  .plyr-container {
    margin: 0 -15px;
  }
}
</style>