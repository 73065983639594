import  { isPlatform } from '@ionic/vue'

const v = (path: string) => isPlatform('capacitor') ? `/assets/videos/${path}` : `https://accessvr-public-static.s3.us-east-1.amazonaws.com/taxonomy/${path}`

export const taxonomy = {
  intro: {
    video: v('00_What is the Taxonomy_fc856b36-59de-11ed-a509-5ef6bb69a334.mp4'),
    thumb: v('00_What is the taxonomy_1.1.1_1.1.1.jpg'),
  },
  categories: [
    {
      name: 'Interactivity',
      id: 'interactivity',
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      video: v('01_Interactivity_fc86d84a-59de-11ed-985a-66cb1d25d42c.mp4'),
      thumb: v('01_Interactivity_1.2.1_1.2.1.jpg'),
      elements: [
        {
          name: 'Passive',
          id: 'passive',
          degrees: 0,
          description: 'The participant has no ability to interact with or influence the experience, such as an audience member watching a movie',
          demo: 'D1ZYhVpdXbQ'
        },
        {
          name: 'Interactive',
          id: 'interactive',
          degrees: 1,
          description: 'The participant has the ability to trigger actions in the experience',
          demo: 'eYIjuMCe0CI'
        },
        {
          name: 'Problem Solving',
          id: 'problem-solving',
          degrees: 2,
          description: 'The participant has the ability to resolve challenges within the experiences',
          demo: 'MMHs70udP-8',
        },
        {
          name: 'Physicalized',
          id: 'physicalized',
          degrees: 3,
          description: 'The participant has a physical representation, or avatar, that is engaged in the experience',
          demo: 'IKoVaYKjYNY'
        },
        {
          name: 'Interpersonal',
          id: 'interpersonal',
          degrees: 4,
          description: 'The participant is able to communicate with or relate to someone or something else within the experience',
          demo: 'r6ZNJ-Ko0wE'
        },
      ]
    },
    {
      name: 'Embodiment',
      id: 'embodiment',
      video: v('02_Embodiment_fc82a6ee-59de-11ed-837d-de1cbe889a52.mp4'),
      thumb: v('02_embodiment_1.3.1_1.3.1.jpg'),
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      elements: [
        {
          name: 'Detached',
          id: 'detached',
          degrees: 0,
          description: 'The participant has an external voyeuristic view of the experience',
          demo: 'j7b-zFUlV_o',
        },
        {
          name: 'Watcher',
          id: 'watcher',
          degrees: 1,
          description: 'The participant is within the experience with a bird\'s eye view, and their role is merely an observer',
          demo: 'qloes6kyNjI',
        },
        {
          name: 'First-Person Point of View',
          id: 'first-person-pov',
          degrees: 2,
          description: 'The participant views the experience from a first-person perspective. They are living the experience but they cannot control their movement',
          demo: '7nj18rdMIWc',
        },
        {
          name: 'Movement',
          id: 'movement',
          degrees: 3,
          description: 'The participant has a locomotion ability which enhances their sense of personal will',
          demo: 'VvuI2V7XwEI',
        },
        {
          name: 'Human-to-Human Interaction',
          id: 'h2h-interaction',
          degrees: 4,
          description: 'Participants can explore relationships with other participants, as avatars, within the experience',
          demo: '5oVCzk8xWX8',
        },
      ]
    },
    {
      name: 'Co-Participation',
      id: 'co-participation',
      video: v('03_Co-Participation_fc8ac5f4-59de-11ed-9272-fec5cf524a04.mp4'),
      thumb: v('03_co-participation_1.4.1_1.4.1.jpg'),
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      elements: [
        {
          name: 'Single-Player',
          id: 'single-player',
          degrees: 0,
          description: 'The participant is alone with no other real person contact',
          demo: '7IirsL9sdqY',
        },
        {
          name: 'One-on-One',
          id: 'one-on-one',
          degrees: 1,
          description: 'Two participants are able to interact with each other, alone',
          demo: '-wozVC6MKsQ',
        },
        {
          name: 'Group',
          id: 'group',
          degrees: 2,
          description: 'More than two participants can experience the environment at the same time',
          demo: 'JwBk3LnIcRo',
        },
        {
          name: 'MMO',
          id: 'mmo',
          degrees: 3,
          description: 'Participants interact in a large, online experience, playing synchronously in a story-driven world',
          demo: 'KMGYnAPOiu0',
        },
        {
          name: 'Secondary Perspective',
          id: 'secondary-perspective',
          degrees: 4,
          description: 'An external point of view on the experience that allows for indirect participation',
          demo: 'mC8QoRa8y_Q',
        },
      ]
    },
    {
      name: 'Story',
      id: 'story',
      video: v('04_Story_fc89587c-59de-11ed-9808-3a607aa01279.mp4'),
      thumb: v('04_story_1.5.1_1.5.1.jpg'),
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      elements: [
        {
          name: 'No Story',
          id: 'no-story',
          degrees: 0,
          description: 'The experience has no context',
          demo: '',
        },
        {
          name: 'Setting',
          id: 'setting',
          degrees: 1,
          description: 'The experience relies upon story aspects to establish a context for time and place',
          demo: '',
        },
        {
          name: 'Pre-Created',
          id: 'pre-created',
          degrees: 2,
          description: 'The story is like a movie or a television show that the participant has no influence over',
          demo: '',
        },
        {
          name: 'Choose Your Own',
          id: 'choose-your-own',
          degrees: 3,
          description: 'The experience allows the participant to make individual choices that shape the direction of the story',
          demo: '',
        },
        {
          name: 'Interactive Story',
          id: 'interactive-story',
          degrees: 4,
          description: 'The participant is the protagonist within the story, can influence events, and can make independent choices that will determine the outcome',
          demo: '',
        },
      ]
    },
    {
      name: 'Dynamics',
      id: 'dynamics',
      video: v('05_Dynamics_ff6de0e4-59de-11ed-a49b-9aea4966dc4f.mp4'),
      thumb: v('05_Dynamics_1.6.1_1.6.1.jpg'),
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      elements: [
        {
          name: 'Pre-determined',
          id: 'pre-determined',
          degrees: 0,
          description: 'The participant is unable to influence the outcomes of the experience',
          demo: '',
        },
        {
          name: 'Choice',
          id: 'choice',
          degrees: 1,
          description: 'The participant can make a decision when faced with one or more possibilities',
          demo: '',
        },
        {
          name: 'Free Will',
          id: 'free-will',
          degrees: 2,
          description: 'The participant perceives either a real or imagined unfettered ability to choose their own experience',
          demo: '',
        },
        {
          name: 'Convo-Reality',
          id: 'convo-reality',
          degrees: 3,
          description: 'The participant can engage in interpersonal communication, can develop deeper relationships, and can live through conflict-driven situations',
          demo: '',
        },
        {
          name: 'Adjustable Point of View',
          id: 'adjustable-pov',
          degrees: 4,
          description: 'The participant has the ability to shift between and influence the environment from multiple characters or perspectives',
          demo: '',
        },
      ]
    },
    {
      name: 'Gamification',
      id: 'gamification',
      video: v('06_Gamification_ffcd6b40-59de-11ed-810d-de1cbe889a52.mp4'),
      thumb: v('06_gamification_1.7.1_1.7.1.jpg'),
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      elements: [
        {
          name: 'No Gamification',
          id: 'no-gamification',
          degrees: 0,
          description: 'Nothing instructs or induces play or competition',
          demo: '',
        },
        {
          name: 'Instruction',
          id: 'instruction',
          degrees: 1,
          description: 'The participant has a set of guidelines to follow in in the experience',
          demo: '',
        },
        {
          name: 'External Process',
          id: 'external-process',
          degrees: 2,
          description: 'The participant is given a set of rules as a means to succeed',
          demo: '',
        },
        {
          name: 'Reinforcement',
          id: 'reinforcement',
          degrees: 3,
          description: 'The participant is given cues that encourage participation and reinforce moments of success or failure',
          demo: '',
        },
        {
          name: 'Reward System',
          id: 'reward-system',
          degrees: 4,
          description: 'There is a score or reward(s) for passing levels or accomplishing goals',
          demo: '',
        },
      ]
    },
    {
      name: 'Immersive Technology',
      id: 'immersive-technology',
      video: v('07_Immersive%20Technology_004400d4-59df-11ed-8a42-0e4600e85639.mp4'),
      thumb: v('07_Immersive Tech_1.8.1_1.8.1.jpg'),
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      elements: [
        {
          name: 'None',
          id: 'none',
          degrees: 0,
          description: 'No immersive technology is used',
          demo: '',
        },
        {
          name: 'Augmented Reality (AR)',
          id: 'ar',
          degrees: 1,
          description: 'Participant\'s view is augmented by computer-generated imagery, superimposed on top of the real world',
          demo: '',
        },
        {
          name: '360 Media',
          id: '360-media',
          degrees: 2,
          description: 'Immersive videos allow participants to be surrounded by a photo-realistic environment, typically involving three degrees of freedom (3DoF) of motion',
          demo: '',
        },
        {
          name: 'Virtual Reality (VR)',
          id: 'vr',
          degrees: 3,
          description: 'An artificial, computer-generated environment surrounds the participant and engages their audio, visual, and at times haptic, senses (usually involves 6DoF of motion)',
          demo: '',
        },
        {
          name: 'Extended/ Cross Reality (XR)',
          id: 'xr',
          degrees: 4,
          description: 'Combinations of these technologies that can include more than one of these types in unison and/or an interaction between the real and virtual world',
          demo: '',
        },
      ]
    },
    {
      name: 'Meta-Control',
      id: 'meta-control',
      video: v('08_Meta%20Control_008cc486-59df-11ed-8aeb-6a4e80ccafb5.mp4'),
      thumb: v('08_Meta control_1.9.1_1.9.1.jpg'),
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      elements: [
        {
          name: 'No Meta-Control',
          id: 'no-meta-control',
          degrees: 0,
          description: 'The participant has no control over the experience',
          demo: '',
        },
        {
          name: 'Journey',
          id: 'journey',
          degrees: 1,
          description: 'The participant can choose events to participate in',
          demo: '',
        },
        {
          name: 'Character',
          id: 'character',
          degrees: 2,
          description: 'The user can customize their character or avatar',
          demo: '',
        },
        {
          name: 'World Editor',
          id: 'world-editor',
          degrees: 3,
          description: 'The participant can edit the levels and existing assets of the environment they inhabit',
          demo: '',
        },
        {
          name: 'World Builder',
          id: 'world-builder',
          degrees: 4,
          description: 'The participant can affect the global functionality of the world and impact other participants\' experiences',
          demo: '',
        },
      ]
    },
    {
      name: 'Didactic Capacity',
      id: 'didactic-capacity',
      video: v('09_Didactic%20Capacity_01e133d0-59df-11ed-8722-961987a9dbc4.mp4'),
      thumb: v('09_didactic capacity_1.10.1_1.10.1.jpg'),
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      elements: [
        {
          name: 'Elemental',
          id: 'elemental',
          degrees: 0,
          description: 'No knowledge is conveyed beyond the functionality of the experience',
          demo: '',
        },
        {
          name: 'Explicit',
          id: 'explicit',
          degrees: 1,
          description: 'Learning is direct and instructional, as is present in a typical classroom lecture',
          demo: '',
        },
        {
          name: 'Implicit',
          id: 'implicit',
          degrees: 2,
          description: 'Learning is derived and discovered, where assumptions and connections are formed',
          demo: '',
        },
        {
          name: 'Recall',
          id: 'recall',
          degrees: 3,
          description: 'Prior information is required to succeed or proceed in the experience',
          demo: '',
        },
        {
          name: 'Synthesis',
          id: 'synthesis',
          degrees: 4,
          description: 'Participant incorporates multiple concepts, ideas, and functionalities to solve a problem within the experience',
          demo: '',
        },
      ]
    },
    {
      name: 'Data',
      id: 'data',
      video: v('10_Data_027804ea-59df-11ed-8288-9a55e913224a.mp4'),
      thumb: v('10_data_1.11.1.jpg'),
      description: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      elements: [
        {
          name: 'Anonymous',
          id: 'anonymous',
          degrees: 0,
          description: 'All data collected by the experience is anonymized and cannot be related back to its participants',
          demo: '',
        },
        {
          name: 'Identity',
          id: 'identity',
          degrees: 1,
          description: 'The experience asks for credentials from the participant, like a participant name and password, and data collected by the experience can be related to that participant',
          demo: '',
        },
        {
          name: 'In-Game',
          id: 'in-game',
          degrees: 2,
          description: 'Data collected is based on the participant’s decisions throughout the experience',
          demo: '',
        },
        {
          name: 'Personalization',
          id: 'personalization',
          degrees: 3,
          description: 'The experience applies background and demographic information about its participants and adapts itself to those characteristics',
          demo: '',
        },
        {
          name: 'Biometrics',
          id: 'biometrics',
          degrees: 4,
          description: 'Real-time data is collected about the participant’s body through specialized hardware, e.g., eye tracking, blood pressure tracker, etc.',
          demo: '',
        },
      ]
    }
  ],
}

export const getCategory = (id: string) => {
  for (let i = 0; i < taxonomy.categories.length; i++) {
    if (taxonomy.categories[i].id === id) {
      return Object.assign({}, taxonomy.categories[i], {
        previous: i > 0 ? taxonomy.categories[i - 1] : null,
        next: i < taxonomy.categories.length - 1 ? taxonomy.categories[i + 1] : null,
      })
    }
  }
  return null
}

export const getElement = (categoryId: string, elementId: string) => {
  const category = getCategory(categoryId)
  if (category !== null) {
    for (let i = 0; i < category.elements.length; i++) {
      if (category.elements[i].id === elementId) {
        return category.elements[i]
      }
    }
  }

  return null
}