<template>
  <ion-button
    :color="color"
    :size="size"
    :fill="fill"
    @click="downloadSessions()"
  >
    <ion-icon slot="start" :icon="downloadOutline"></ion-icon>
    Download Sessions
  </ion-button>
</template>

<script>
import { defineComponent } from 'vue'
import { IonButton, isPlatform, IonIcon, alertController } from '@ionic/vue'
import { downloadOutline } from 'ionicons/icons';
import { db } from '../data/db'
import download from 'downloadjs'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'

export default defineComponent({
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    color: {
      type: String,
      default: 'light',
    },
    size: {
      type: String,
      default: 'default',
    },
    fill: {
      type: String,
      default: 'solid',
    },
  },
  setup() {
    const downloadSessions = async () => {
      const blob = await db.export({prettyJson: true})
      if (isPlatform('capacitor')) {
        const json = await blob.text()
        const result = await Filesystem.writeFile({
          path: "accessvr-explore-taxonomy-sessions.json",
          data: json,
          directory: Directory.Documents,
          encoding: Encoding.UTF8,
        })
        const alert = await alertController.create({
          header: 'File Saved',
          message: result.uri,
          buttons: ['OK'],
        })
        await alert.present()
      } else {
        download(blob, "sessions.json", "application/json")
      }
    }

    return {
      downloadOutline,
      downloadSessions,
    }
  },
})
</script>