<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
        </ion-buttons>
        <ion-title>Explore Immersion</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense" class="ion-padding">
        <ion-toolbar>
          <ion-title size="large">Explore Immersion</ion-title>
        </ion-toolbar>
      </ion-header>

      <div class="ion-padding">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-card>
                <img alt="The Immersive Experience Taxonomy" src="/assets/images/vr-headset.jpg" />
                <ion-card-header>
                  <ion-card-title>The Immersive Experience Taxonomy</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <div class="ion-padding-bottom">
                    To help designers create experiences that engage individuals inside immersive technologies,
                    JJ Ruscella and Dr. Mohammad Obeid developed the Immersive Experience Taxonomy.
                  </div>
                  <div>
                    <ion-button
                      :router-link="{ name: 'TaxonomyHome' }"
                      router-direction="none"
                      color="secondary"
                    >
                      Explore
                    </ion-button>
                  </div>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col>
              <ion-card>
                <img alt="Your Dream Scenario" src="/assets/images/pa.jpg" />
                <ion-card-header>
                  <ion-card-title>Your Dream Scenario</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <div class="ion-padding-bottom">
                    Dreaming of the perfect training scenario for your students? Share
                    your vision with us, and let's dream together.
                  </div>
                  <div>
                    <dream-scenario-survey-button>
                      Take The Survey
                    </dream-scenario-survey-button>
                  </div>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <div class="ion-padding" style="text-align:center; margin-top:100px;">
        <a href="https://www.accessvr.com" target="_blank">
          <img src="/assets/logos/AVR-logo-EmpoweredBy.png" style="max-width:33%;">
        </a>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol, IonButtons, IonButton, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/vue';
import DreamScenarioSurveyButton from '../components/DreamScenarioSurveyButton.vue'

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonContent,
    IonTitle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    DreamScenarioSurveyButton,
  },
  setup () {
    return {

    }
  },
})
</script>