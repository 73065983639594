<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons
          slot="start"
        >
          <ion-menu-button></ion-menu-button>
          <ion-back-button
            v-if="!$store.state.session.id"
            :router-link="{ name: 'TaxonomyHome' }"
          ></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <your-report-button
            v-model="showReportModal"
            color="dark"
          ></your-report-button>
        </ion-buttons>
        <ion-title>{{ category.name }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding">
      <div id="container">
        <ion-grid>
          <ion-row>
            <ion-col>
              <div class="ion-padding-start ion-padding-end">
                <video-player
                  ref="video"
                  :poster="category.thumb"
                  :src="category.video"
                ></video-player>
              </div>
            </ion-col>
            <ion-col>
              <h1>{{ category.name }}</h1>
              <h3>How many degress of {{ category.name }} are in your immersive experience?</h3>
              <p>Mark all that apply.</p>
            </ion-col>
          </ion-row>
        </ion-grid>


        <ion-card
          v-for="element in category.elements"
          :key="`element_${element.id}`"
          :class="[
            'taxonomy-element',
            {
              selected: $store.state.session.taxonomy.categories[category.id][element.id].selected,
            }
          ]"
        >
          <div style="padding: 0.5rem;">
            <ion-grid>
              <ion-row class="ion-align-items-center">
                <ion-col size="8">
                  <div class="">
                    <ion-grid>
                      <ion-row class="ion-align-items-center">
                        <ion-col
                          v-if="$store.state.session.id"
                          size="2"
                        >
                          <ion-toggle
                            color="secondary"
                            v-model="$store.state.session.taxonomy.categories[category.id][element.id].selected"
                          ></ion-toggle>
                        </ion-col>
                        <ion-col
                          :size="$store.state.session.id ? 10 : 12"
                          class="ion-padding-end"
                        >
                          <div style="font-size:1.1rem; font-weight:bold; color:var(--ion-text-color); padding-bottom:0.5rem;">
                            {{ element.name }}
                          </div>
                          {{ element.description }}
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </div>
                </ion-col>
                <ion-col size="2" style="text-align:center;">
                  <ion-button
                    v-if="$store.state.session.id"
                    fill="clear"
                    color="dark"
                    @click="editNotes(category.id, element.id)"
                  >
                    <ion-icon
                      slot="icon-only"
                      :icon="createOutline"
                    ></ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col size="2">
                  <div class="big-degrees">
                    <div class="degrees">
                      {{ element.degrees }}
                    </div>
                    <div class="subtitle">
                      Degrees
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-card>
      </div>
    </ion-content>

    <taxonomy-toolbar
      v-if="$store.state.session.id"
      :next="true"
      :previous="true"
      @next="onNext"
      @previous="onPrevious"
    ></taxonomy-toolbar>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { modalController, useIonRouter, IonIcon, IonButton, IonButtons, IonMenuButton, IonContent, IonHeader, IonBackButton, IonPage, IonTitle, IonToolbar, IonCard, IonToggle, IonGrid, IonRow, IonCol } from '@ionic/vue';
import YourReportButton from '../../components/YourReportButton'
import TaxonomyToolbar from '../../components/TaxonomyToolbar'
import NotesModal from '../../components/NotesModal'
import VideoPlayer from '../../components/VideoPlayer'
import { createOutline } from 'ionicons/icons';

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonContent,
    IonTitle,
    IonBackButton,
    IonButton,
    IonCard,
    IonIcon,
    IonToggle,
    IonMenuButton,
    IonGrid,
    IonRow,
    IonCol,
    YourReportButton,
    TaxonomyToolbar,
    VideoPlayer,
  },
  props: {
    category: {
      type: Object,
    }
  },
  data () {
    return {
      showReportModal: false
    }
  },
  setup () {
    const router = useIonRouter()

    return {
      createOutline,
      router,
      ionViewWillLeave() {
        this.$refs.video.pause()
      },
    }
  },
  computed: {
    //
  },
  methods: {
    onNext () {
      if (this.category.next) {
        this.router.push({
          name: 'TaxonomyCategory',
          params: {
            categoryId: this.category.next.id,
          }
        }, 'forward')
      } else {
        this.showReportModal = true
      }
    },
    onPrevious () {
      if (this.category.previous) {
        this.router.push({
          name: 'TaxonomyCategory',
          params: {
            categoryId: this.category.previous.id,
          }
        }, 'back')
      } else {
        this.router.push({
          name: 'TaxonomyProjectPage',
        }, 'back')
      }
    },
    async editNotes (categoryId, elementId) {
      const modal = await modalController.create({
        component: NotesModal,
        componentProps: {
          categoryId,
          elementId
        }
      })
      modal.present()
    }
  }
})
</script>

<style lang="scss" scoped>
ion-card.taxonomy-element {
  border: 3px solid var(--ion-card-background);
  &.selected {
    border: 3px solid var(--ion-color-secondary);
    background-color: darken(#ff7300, 40%);
  }
}
</style>