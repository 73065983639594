// @see https://stackblitz.com/edit/typescript-dexie-appdemo?file=appdb.ts

import Dexie from 'dexie'
import 'dexie-export-import'

export class Explorer extends Dexie {

  sessions!: Dexie.Table<Session, number | null | undefined>;

  constructor() {
    super("Explorer")

    this.version(1).stores({
      sessions: '++id, uuid, email, persisted, persistedAt'
    })

    this.version(2).stores({
      surveys: '++id',
    })
  }

}

export interface Session {
  id?: number,
  uuid?: string | null,
  email?: string | null,
  type?: string | null,
  persisted?: boolean,
  persistedAt?: Date | null,
}

export interface Survey {
  id?: number,
}

export const db = new Explorer()