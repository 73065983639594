<template>
  <div class="tax-table">
    <section
      class="tax-table-degrees"
    >
      <div
        class="tax-table-degrees-head"
      >
        Degrees
      </div>
      <div class="tax-table-degrees-el">
        0
      </div>
      <div class="tax-table-degrees-el">
        1
      </div>
      <div class="tax-table-degrees-el">
        2
      </div>
      <div class="tax-table-degrees-el">
        3
      </div>
      <div class="tax-table-degrees-el">
        4
      </div>
    </section>
    <section
      v-for="category in categories"
      :key="`category_${category.id}`"
      class="tax-table-category"
    >
      <div
        class="tax-table-category-head"
        @click.prevent="$emit('goToCategory', category)"
      >
        <span>{{ category.name }}</span>
      </div>
      <a
        v-for="element in category.elements"
        :key="`element_${element.id}`"
        href="javascript:;"
        :class="[
          'tax-table-category-el',
          {
            'selected': $store.state.session.taxonomy.categories[category.id][element.id].selected
          }
        ]"
        @click.prevent="$emit('goToCategory', category)"
      >
        <span>{{ element.name }}</span>
        <ion-icon
          v-if="$store.state.session.taxonomy.categories[category.id][element.id].notes"
          :icon="createOutline"
          color="light"
        ></ion-icon>
      </a>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { taxonomy } from '../data/taxonomy'
import { IonIcon } from '@ionic/vue'
import { createOutline } from 'ionicons/icons';

export default defineComponent({
  components: {
    IonIcon,
  },
  setup () {
    return {
      createOutline,
      categories: taxonomy.categories,
    }
  }
})
</script>

<style lang="scss" scoped>
.tax-table {

  background-color: white;
  border: 1px solid white;
  border-bottom: 0;

  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 0;
  height: 400px;

  .tax-table-category,
  .tax-table-degrees {
    display: grid;
  }

  .tax-table-category,
  .tax-table-degrees {

    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 1px;
    margin-bottom: 1px;

    .tax-table-category-head,
    .tax-table-degrees-head {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      text-transform: uppercase;
      font-weight: 900;

      font-size: 10px;
      text-align: center;
      height: 100%;
    }

    .tax-table-category-head,
    .tax-table-degrees-head {
      background-color: var(--ion-color-primary);
    }

    .tax-table-category-el,
    .tax-table-degrees-el {
      cursor: pointer;
      align-self: center;
      display: flex;
      text-decoration: none;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      position: relative;

      font-size: 10px;
      text-align: center;
      height: 100%;
    }

    .tax-table-category-el {
      &.selected {
        background-color: lighten(#ff7300, 10%);
      }
    }

    .tax-table-category-el {
      ion-icon {
        position: absolute;
        left: 5px;
        bottom: 5px;
        width: 15px;
        height: 15px;
      }
    }

    .tax-table-category-el {
      background-color: lightgray;
      color: #111111;
    }

    .tax-table-degrees-el {
      background-color: darken(#666666, 20%);
      color: white;
      font-weight: 900;
      font-size: 18px;
    }

  }
}
</style>