<template>
  <ion-button
    color="secondary"
    @click="openModal()"
  >
    <strong
      v-if="$store.state.session.id"
    >
      {{ totalDegrees }} / 100
    </strong>
    <ion-icon slot="end" :icon="gridOutline"></ion-icon>
  </ion-button>
</template>

<script>
import { IonButton, IonIcon, modalController } from '@ionic/vue'
import { gridOutline } from 'ionicons/icons'
import { mapGetters } from 'vuex'
import TaxonomyTableModal from './YourReportModal.vue'

export default {
  props: {
    modelValue: {
      type: Boolean,
    }
  },
  components: {
    IonButton,
    IonIcon,
  },
  mounted () {
    if (this.modelValue) {
      this.openModal()
    }
  },
  data () {
    return {
      modalOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      "totalDegrees",
    ])
  },
  setup () {
    return {
      gridOutline,
    }
  },
  watch: {
    modelValue (open) {
      if (open && !this.modalOpen) {
        this.openModal()
      } else {
        // TODO: force close?
      }
    },
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: TaxonomyTableModal,
        cssClass: 'fullscreen',
      });
      modal.present()
      this.modalOpen = true
      this.$emit('update:modelValue', true)
      await modal.onWillDismiss()
      this.$emit('update:modelValue', false)
      this.modalOpen = false
    }
  }
}
</script>

