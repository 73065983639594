<template>
  <ion-button
    :color="color"
    :size="size"
    :fill="fill"
    @click="confirmResetSession()"
  >
    <ion-icon slot="start" :icon="refreshOutline"></ion-icon>
    Reset Session
  </ion-button>
</template>

<script>
import { defineComponent } from 'vue'
import { useIonRouter, IonButton, IonIcon, actionSheetController } from '@ionic/vue'
import { refreshOutline } from 'ionicons/icons'
import { useStore } from '../data/store'

export default defineComponent({
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    color: {
      type: String,
      default: 'light',
    },
    size: {
      type: String,
      default: 'default',
    },
    fill: {
      type: String,
      default: 'solid',
    },
  },
  setup(props, { emit }) {
    const store = useStore()
    const ionRouter = useIonRouter()

    const confirmResetSession = async () => {
      const actionSheet = await actionSheetController.create({
        header: 'Are you sure?',
        buttons: [
          {
            text: 'Yes, reset',
            role: 'destructive',
            data: {
              action: 'confirm',
            },
          },
          {
            text: 'No, keep exploring',
            data: {
              action: 'cancel',
            },
          },
        ]
      })

      await actionSheet.present()

      const result = await actionSheet.onDidDismiss()

      if (result.data.action === 'confirm') {
        store.dispatch('resetSession')
        ionRouter.replace({
          name: 'TaxonomyHome',
        })
        emit('reset')
      }
    }

    return {
      confirmResetSession,
      refreshOutline,
    }
  },
})
</script>