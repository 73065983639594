<template>
  <ion-button
    color="secondary"
    @click="openModal()"
  >
    Take The Survey
  </ion-button>
</template>

<script>
import { IonButton, modalController } from '@ionic/vue'
import DreamScenarioSurveyModal from './DreamScenarioSurveyModal.vue'

export default {
  props: {
    modelValue: {
      type: Boolean,
    }
  },
  components: {
    IonButton,
  },
  mounted () {
    if (this.modelValue) {
      this.openModal()
    }
  },
  data () {
    return {
      modalOpen: false,
    }
  },
  computed: {
    //
  },
  setup () {
    return {
      //
    }
  },
  watch: {
    modelValue (open) {
      if (open && !this.modalOpen) {
        this.openModal()
      } else {
        // TODO: force close?
      }
    },
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: DreamScenarioSurveyModal,
        cssClass: 'fullscreen',
      });
      modal.present()
      this.modalOpen = true
      this.$emit('update:modelValue', true)
      await modal.onWillDismiss()
      this.$emit('update:modelValue', false)
      this.modalOpen = false
    }
  }
}
</script>

