<template>
  <ion-header>
    <ion-toolbar color="light">
      <ion-buttons slot="start">
        <ion-button @click="close" color="dark">
          Cancel
        </ion-button>
      </ion-buttons>
      <ion-title>Send Your Report</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="send" color="secondary">
          <ion-icon slot="start" :icon="paperPlaneOutline"></ion-icon>
          Send
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>
      <ion-item>
        <ion-label>Name</ion-label>
        <ion-input
          v-model="$store.state.session.profile.name"
          placeholder="Steve Jobs"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Email</ion-label>
        <ion-input
          v-model="$store.state.session.email"
          placeholder="stevejobs@apple.com"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Phone (Optional)</ion-label>
        <ion-input
          v-model="$store.state.session.profile.phone"
          placeholder="(555) 555-1212"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Title (Optional)</ion-label>
        <ion-input
          v-model="$store.state.session.profile.title"
          placeholder="Chief Executive Officer"
        ></ion-input>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue';
import { IonIcon, IonList, IonItem, IonLabel, IonInput, IonButtons, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, modalController } from '@ionic/vue';
import { paperPlaneOutline } from 'ionicons/icons'
import { useStore } from '../data/store'

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonIcon,
  },
  props: {

  },
  data () {
    return {
      //
    }
  },
  mounted () {
    //
  },
  setup () {
    const store = useStore()

    return {
      paperPlaneOutline,
      close() {
        return modalController.dismiss(null, 'close')
      },
      send() {
        store.dispatch('saveSession')
        return modalController.dismiss(null, 'close')
      }
    }
  },
  computed: {
    //
  }
})
</script>

<style lang="scss" scoped>

</style>