<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>Your Report</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="close" color="dark">
          <ion-icon :icon="closeOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div class="rotate-screen display-portrait">
      <div>
        <ion-icon :icon="tabletPortraitOutline" color="" size="large"></ion-icon>
        <ion-icon :icon="arrowForwardOutline" color="" size="large"></ion-icon>
        <ion-icon :icon="tabletLandscapeOutline" color="" size="large" style="padding-left:5px;"></ion-icon>
        <div class="ion-padding-vertical" style="margin-bottom:100px;">
          Rotate your screen
        </div>
        <ion-button
          color="light"
          @click="close()"
        >
          Close Report
        </ion-button>
      </div>
    </div>

    <div class="display-landscape">
      <div
        v-if="$store.state.session.id"
        style="text-align:right;"
        class="ion-padding-bottom"
      >
        <div class="big-degrees" style="display:inline-block;">
          <div class="degrees">
            <span>{{ totalDegrees }}</span><span style="color:var(--ion-color-step-400);"> / 100</span>
          </div>
        </div>
      </div>
      <div class="ion-padding-bottom">
        <taxonomy-table
          @goToCategory="(category) => closeAndGoToCategory(category)"
        ></taxonomy-table>
        <div class="ion-padding-top" style="text-align:right;">
          <ion-button
            color="light"
            @click="close()"
          >
            Close Report
          </ion-button>
        </div>
      </div>
    </div>
  </ion-content>

  <ion-footer>
    <ion-toolbar>
      <ion-buttons slot="start">
        <reset-session-button
          size="large"
          fill="solid"
          @reset="close()"
        ></reset-session-button>
      </ion-buttons>
      <div style="text-align:center;">
        <img src="/assets/logos/AVR-logo-EmpoweredBy.png" style="max-height:70px">
      </div>
      <ion-buttons slot="end">
        <send-report-button
          size="large"
          fill="solid"
          color="secondary"
        ></send-report-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import { defineComponent } from 'vue';
import { useIonRouter, IonFooter, IonIcon, IonButtons, IonContent, IonButton, IonHeader, IonTitle, IonToolbar, modalController } from '@ionic/vue';
import { closeOutline, tabletPortraitOutline, tabletLandscapeOutline, arrowForwardOutline } from 'ionicons/icons';
import TaxonomyTable from './TaxonomyTable.vue';
import { mapGetters } from 'vuex';
import ResetSessionButton from './ResetSessionButton'
import SendReportButton from './SendReportButton'

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonContent,
    IonTitle,
    IonButton,
    TaxonomyTable,
    IonIcon,
    IonFooter,
    ResetSessionButton,
    SendReportButton,
  },
  props: {
    sessionId: {
      type: String,
      default: null,
    }
  },
  mounted () {
    //
  },
  setup () {
    const ionRouter = useIonRouter()

    return {
      tabletPortraitOutline,
      tabletLandscapeOutline,
      arrowForwardOutline,
      closeOutline,
      close () {
        return modalController.dismiss(null, 'close')
      },
      closeAndGoToCategory (category) {
        ionRouter.push({
          name: 'TaxonomyCategory',
          params: {
            categoryId: category.id,
          },
        })
        return modalController.dismiss(null, 'close')
      }
    }
  },
  computed: {
    ...mapGetters([
      'totalDegrees',
    ])
  }
})
</script>

<style lang="scss" scoped>
@media (orientation: portrait) {
  .display-landscape {
    display: none !important;
  }
}
@media (orientation: landscape) {
  .display-portrait {
    display: none !important;
  }
}

.rotate-screen {
  text-align: center;
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ion-icon {
    width: 1em;
    height: 1em;
  }
}

ion-footer {
  @media (orientation: portrait) {
    display: none;
  }
  ion-toolbar {
    ion-button {
      --padding-start: 40px;
      --padding-end: 40px;
      height: 48px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
</style>