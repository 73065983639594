import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"margin-top":"2rem"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_split_pane, {
        "content-id": "main-content",
        when: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            "content-id": "main-content",
            type: "overlay",
            color: "primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, { class: "" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_ion_list, { id: "inbox-list" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appPages, (p, i) => {
                          return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            "auto-hide": "false",
                            key: i
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                "router-direction": "none",
                                "router-link": p.url,
                                lines: "none",
                                detail: "false",
                                class: _normalizeClass([
                    'hydrated',
                    {
                      selected: _ctx.isSelected(p.names),
                    }
                  ])
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    ios: p.iosIcon,
                                    md: p.mdIcon
                                  }, null, 8, ["ios", "md"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(p.title), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["router-link", "class"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_router_outlet, { id: "main-content" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}