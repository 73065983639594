<template>
  <ion-footer>
    <ion-toolbar :translucent="true">
      <ion-buttons slot="start" class="ion-padding">
        <ion-button
          size="large"
          color="secondary"
          fill="outline"
          @click.prevent="$emit('previous')"
          :disabled="!previous"
        >
          Previous
        </ion-button>
      </ion-buttons>
      <div style="text-align:center;">
        <img src="/assets/logos/AVR-logo-EmpoweredBy.png" style="max-height:70px">
      </div>
      <ion-buttons slot="end" class="ion-padding">
        <ion-button
          size="large"
          color="secondary"
          fill="solid"
          @click.prevent="$emit('next')"
          :disabled="!next"
        >
          Next
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButtons, IonButton, IonFooter, IonToolbar } from '@ionic/vue';

export default defineComponent({
  props: {
    previous: {
      type: [String, Boolean],
      required: false,
    },
    next: {
      type: [String, Boolean],
      required: false,
    },
  },
  components: {
    IonFooter,
    IonToolbar,
    IonButtons,
    IonButton,
  },
  setup () {
    return {
      //
    }
  }
})
</script>

<style lang="scss" scoped>
ion-toolbar {
  ion-button {
    --padding-start: 40px;
    --padding-end: 40px;
    height: 48px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
</style>