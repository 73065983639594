<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            :router-link="{ name: 'TaxonomyHome' }"
            color="secondary"
            text="Home"
          ></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <your-report-button></your-report-button>
        </ion-buttons>
        <ion-title>Taxonomy</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-header collapse="condense">
      <ion-toolbar>
        <ion-title size="large">Explore The Taxonomy</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ion-padding">

      <div id="container">
        <p class="ion-padding-bottom">Explore the Immersive Experience Taxonomy by viewing each section listed below.</p>

        <ion-list>
          <ion-item
            :router-link="{ name: 'TaxonomyOrganization' }"
          >
            <ion-icon :icon="ellipseOutline" size="large" slot="start"></ion-icon>
            <ion-label>
              You and Your Organization
            </ion-label>
            <ion-icon :icon="chevronForwardOutline" size="small" slot="end"></ion-icon>
          </ion-item>
          <ion-item
            :router-link="{ name: 'TaxonomyProject' }"
          >
            <ion-icon :icon="ellipseOutline" size="large" slot="start"></ion-icon>
            <ion-label>
              Your Project
            </ion-label>
            <ion-icon :icon="chevronForwardOutline" size="small" slot="end"></ion-icon>
          </ion-item>
          <ion-item
            v-for="category in categories"
            :key="`category_${category.id}`"
            :router-link="{
              name: 'TaxonomyCategory',
              params: {
                categoryId: category.id,
              }
            }"
          >
            <ion-icon :icon="ellipseOutline" size="large" slot="start"></ion-icon>
            <ion-label>
              {{ category.name }}
            </ion-label>
            <ion-icon :icon="chevronForwardOutline" size="small" slot="end"></ion-icon>
          </ion-item>
          <ion-item
            :router-link="{ name: 'TaxonomyReport' }"
          >
            <ion-icon :icon="ellipseOutline" size="large" slot="start"></ion-icon>
            <ion-label>
              Your Report
            </ion-label>
            <ion-icon :icon="chevronForwardOutline" size="small" slot="end"></ion-icon>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonIcon, IonList, IonItem, IonLabel, IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { chevronForwardOutline, ellipseOutline, checkmarkCircleOutline, gridOutline } from 'ionicons/icons'
import { taxonomy } from '../../data/taxonomy'
import YourReportButton from '../../components/YourReportButton.vue'

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonContent,
    IonTitle,
    IonBackButton,
    IonLabel,
    IonItem,
    IonList,
    IonIcon,
    YourReportButton,
  },
  async mounted () {
    //
  },
  setup () {
    return {
      categories: taxonomy.categories,
      chevronForwardOutline,
      ellipseOutline,
      checkmarkCircleOutline,
      gridOutline,
    }
  }
})
</script>
