import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { db, Session as SessionTable } from './db'
import { taxonomy, getElement } from './taxonomy'

export interface Project {
  name: string,
  purpose: string,
  timeframe: number,
  learners: number,
  budget: number,
}

export interface Profile {
  name: string,
  phone: string,
  title: string,
  organization: string,
}

export interface Session extends Omit<SessionTable, 'id'> {
  id: number | null,
  uuid?: string | null,
  email?: string | null,
  type?: string | null,
  persisted?: boolean,
  persistedAt?: Date | null,
  profile?: Profile,
  project?: Project,
  taxonomy: {
    categories: {
      [id: string]: {
        [id: string]: {
          selected: boolean,
          notes: string
        }
      }
    }
  }
}

export interface State {
  session: Session,
}

export const defaultSession = () => {
  return {
    id: null,
    uuid: null,
    email: null,
    type: null,
    persisted: false,
    persistedAt: null,
    profile: {
      name: '',
      phone: '',
      title: '',
      organization: '',
    },
    project: {
      name: '',
      purpose: '',
      timeframe: 6,
      learners: 1000,
      budget: 100000,
    },
    taxonomy: {
      categories: Object.fromEntries(
        taxonomy.categories.map(category => ([
          category.id,
          Object.fromEntries(
            category.elements.map(element => ([
              element.id,
              {
                selected: false,
                notes: '',
              }
            ]))
          )
        ]))
      )
    }
  }
}

export const store = createStore<State>({
  state: {
    session: defaultSession(),
  },
  actions: {
    async createNewSession(context, payload) {
      const sessionId = await db.sessions.add({ type: payload.sessionType })
      context.state.session = Object.assign({}, defaultSession(), { id: sessionId, type: payload.sessionType })
    },
    async changeSessionType(context, payload) {
      context.state.session.type = payload.sessionType
    },
    async resetSession(context) {
      context.state.session = Object.assign({}, defaultSession())
    },
    async saveSession(context) {
      if (context.state.session.id) {
        const sessionData = JSON.parse(JSON.stringify(context.state.session))
        await db.sessions.update(context.state.session.id, sessionData)
      }
    },
    async saveCategorySelections(context, payload) {
      context.state.session.taxonomy.categories[payload.categoryId] = payload.elements
      await db.sessions.update(context.state.session.id, context.state.session)
    }
  },
  getters: {
    totalDegrees (state) {
      let total = 0
      for (const categoryId in state.session.taxonomy.categories) {
        for (const elementId in state.session.taxonomy.categories[categoryId]) {
          const element = state.session.taxonomy.categories[categoryId][elementId]
          if (element.selected) {
            const elementConfig = getElement(categoryId, elementId)
            if (elementConfig) {
              total += elementConfig.degrees
            }
          }
        }
      }
      return total
    }
  }
})

export const key: InjectionKey<Store<State>> = Symbol()

export function useStore () {
  return baseUseStore(key)
}