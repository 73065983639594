<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>Your Dream Scenario</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content :fullscreen="true" ref="content">
    <ion-header collapse="condense" ref="header">
      <ion-toolbar>
        <ion-title size="large" class="ion-text-center">Your Dream Scenario</ion-title>
      </ion-toolbar>
    </ion-header>

    <div id="container">
      <h3 class="--balanced-padding ion-text-center">
        <div class="ion-padding-vertical">
          Dreaming of the perfect training scenario for your students?<br>
          Share your vision with us.<br>
          Let's dream together.
        </div>
      </h3>
      <ion-list>
        <div class="ion-padding-vertical">
          <h3 class="--balanced-padding">
            <label>
              What is the most critical scenario or procedural moment for your learners to experience?
            </label>
          </h3>
          <div class="ion-padding-vertical ion-color-medium ion-text-center">
            (Short answers are fine!)
          </div>
          <ion-item>
            <ion-textarea v-model="survey.criticalScenarioDescription" placeholder="Type your answer here" autoGrow="true"></ion-textarea>
          </ion-item>
        </div>

        <div
          v-if="survey.hasMoreTime"
        >

          <div class="ion-padding-vertical">
            <h3 class="--balanced-padding">
              What scenario, in your opinion, is the most challenging to replicate through simulation?
            </h3>
            <ion-item>
              <ion-textarea v-model="survey.mostChallengeToReplicate" placeholder="Type your answer here"  autoGrow="true"></ion-textarea>
            </ion-item>
          </div>

          <div class="ion-padding-vertical">
            <h3 class="--balanced-padding">
              Do you currently utilize any e-learning with video content?
            </h3>
            <ion-radio-group v-model="survey.currentlyUtilizeVideo">
              <ion-item>
                <ion-label>Yes</ion-label>
                <ion-radio slot="end" value="yes"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>No</ion-label>
                <ion-radio slot="end" value="no"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>

          <div
            class="ion-padding-vertical"
            v-if="survey.currentlyUtilizeVideo === 'yes'"
          >
            <h3 class="--balanced-padding">
              Are you satisfied with the effectiveness of it?
            </h3>
            <ion-radio-group v-model="survey.effectivenessSatisfaction">
              <ion-item>
                <ion-label>Not satisfied</ion-label>
                <ion-radio slot="end" value="not satisfied"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Moderately satisfied</ion-label>
                <ion-radio slot="end" value="moderately satisfied"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Very satisfied</ion-label>
                <ion-radio slot="end" value="very satisfied"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>

          <div
            class="ion-padding-bottom"
            v-if="survey.currentlyUtilizeVideo === 'yes'"
          >
            <h3 class="--balanced-padding">
              What is the most common scenario depicted in the video content?
            </h3>
            <ion-item>
              <ion-textarea v-model="survey.mostCommonScenario" placeholder="Type your answer here"  autoGrow="true"></ion-textarea>
            </ion-item>
          </div>

          <div class="ion-padding-vertical">
            <h3 class="--balanced-padding">
              Do you have a dedicated facility for running live simulations with your students?
            </h3>
            <ion-radio-group v-model="survey.hasDedicatedFacilityForSimulations">
              <ion-item>
                <ion-label>Yes</ion-label>
                <ion-radio slot="end" value="yes"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>No</ion-label>
                <ion-radio slot="end" value="no"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>

          <div
            class="ion-padding-vertical"
            v-if="survey.hasDedicatedFacilityForSimulations === 'yes'"
          >
            <h3 class="--balanced-padding">
              Do you produce your own scripts and hire your own role players for the scenarios you run, or do you work with an outside agency?
            </h3>
            <ion-radio-group v-model="survey.howYouDoProduction">
              <ion-item>
                <ion-label>In house</ion-label>
                <ion-radio slot="end" value="in house"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Outside agency</ion-label>
                <ion-radio slot="end" value="outside agency"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>

          <div
            class="ion-padding-vertical"
            v-if="survey.hasDedicatedFacilityForSimulations === 'yes'"
          >
            <h3 class="--balanced-padding">
              Which simulation is the most expensive to run?
            </h3>
            <ion-item>
              <ion-textarea v-model="survey.mostExpensiveSimulation" placeholder="Type your answer here"  autoGrow="true"></ion-textarea>
            </ion-item>
          </div>

          <div
            class="ion-padding-vertical"
            v-if="survey.hasDedicatedFacilityForSimulations === 'yes'"
          >
            <h3 class="--balanced-padding">
              Which simulation is run the most often?
            </h3>
            <ion-item>
              <ion-textarea v-model="survey.mostFrequentSimulation" placeholder="Type your answer here"  autoGrow="true"></ion-textarea>
            </ion-item>
          </div>

        </div>

        <div
          class="ion-padding-vertical"
        >
          <h3 class="--balanced-padding">
            Do you want to continue the conversation with AccessVR regarding:
          </h3>

          <ion-item>
            <ion-checkbox @click="survey.doesNotWantConsultation = false" v-model="survey.wantsConsultationOnContentDesign" slot="start"></ion-checkbox>
            <ion-label>Consultation on content design</ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox @click="survey.doesNotWantConsultation = false"  v-model="survey.wantsConsultationOnProduction" slot="start"></ion-checkbox>
            <ion-label>360 production</ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox @click="survey.doesNotWantConsultation = false" v-model="survey.wantsConsultationSomethingElse" slot="start"></ion-checkbox>
            <ion-label>Something else</ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox v-model="survey.doesNotWantConsultation" slot="start"></ion-checkbox>
            <ion-label>Nothing right now, thank you</ion-label>
          </ion-item>
        </div>

        <div
          class="ion-padding-vertical"
          v-if="!survey.doesNotWantConsultation"
        >
          <h3 class="--balanced-padding">
            How should we address you?
          </h3>
          <ion-item>
            <ion-label position="stacked">Your Name</ion-label>
            <ion-input v-model="survey.contact.name" placeholder="Johnny or Jenny Appleseed"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Preferred Email</ion-label>
            <ion-input v-model="survey.contact.email" type="email" placeholder="your@email.com"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Preferred Phone (Optional)</ion-label>
            <ion-input v-model="survey.contact.phone" type="tel" placeholder="+1 555 555-1212"></ion-input>
          </ion-item>
        </div>

        <div
          v-if="!survey.hasMoreTime"
          class="--balanced-padding ion-padding-vertical ion-text-center"
        >
          <div class="ion-padding-vertical">
            <ion-button
              size="large"
              color="secondary"
              fill="outline"
              class="ion-padding-end"
              @click.prevent="done()"
            >
              I'm done!
            </ion-button>
            <ion-button
              size="large"
              color="secondary"
              class="ion-padding-start"
              @click.prevent="survey.hasMoreTime = true"
            >
              Ask me more
            </ion-button>
          </div>
        </div>

        <div
          v-if="survey.hasMoreTime"
          class="ion-padding-vertical ion-text-center"
        >
          <div class="--balanced-padding ion-padding-bottom">
            <ion-button color="secondary" size="large" @click.prevent="done()">
              OK, now I'm done
            </ion-button>
          </div>
        </div>

        <div
          class="ion-padding-vertical ion-text-center"
        >
          <div class="ion-padding-bottom">
            <ion-button
              color="light"
              @click.prevent="confirmCancel()"
            >
              Cancel and delete
            </ion-button>
          </div>
        </div>

        <div class="ion-padding-bottom">
          &nbsp;
        </div>

      </ion-list>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue';
import { modalController, alertController, useIonRouter, IonInput, IonButton, IonTextarea, IonRadioGroup, IonRadio, IonCheckbox, IonList, IonItem, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/vue';
import { db } from '../data/db';

const defaultSurveyData = {
  hasMoreTime: false,
  criticalScenarioDescription: null,
  mostChallengeToReplicate: null,
  currentlyUtilizeVideo: null,
  effectivenessSatisfaction: null,
  mostCommonScenario: null,
  hasDedicatedFacilityForSimulations: null,
  howYouDoProduction: null,
  mostExpensiveSimulation: null,
  mostFrequentSimulation: null,
  wantsConsultationOnContentDesign: false,
  wantsConsultationOnProduction: false,
  wantsConsultationSomethingElse: false,
  doesNotWantConsultation: false,
  contact: {
    name: null,
    email: null,
    phone: null,
  },
}

export default defineComponent({
  name: 'DreamScenarioSurvey',
  components: {
    // IonButtons,
    IonContent,
    IonHeader,
    // IonMenuButton,
    IonTitle,
    IonInput,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonCheckbox,
    IonRadioGroup,
    IonRadio,
    IonButton,
    IonTextarea,
  },
  watch: {
    'survey.doesNotWantConsultation' (doesNotWantConsultation) {
      if (doesNotWantConsultation) {
        this.survey.wantsConsultationOnContentDesign = false
        this.survey.wantsConsultationOnProduction = false
        this.survey.wantsConsultationSomethingElse = false
      }
    },
  },
  setup () {
    const router = useIonRouter()
    return {
      router,
    }
  },
  data () {
    return {
      survey: JSON.parse(JSON.stringify(defaultSurveyData)),
    }
  },
  methods: {
    async reset () {
      this.survey = JSON.parse(JSON.stringify(defaultSurveyData))
    },
    async done () {
      const surveyData = JSON.parse(JSON.stringify(this.survey))
      await db.surveys.add(surveyData)
      this.reset()
      const alert = await alertController.create({
        header: 'Thank you!',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      })
      await alert.present()
      modalController.dismiss(null, 'close')
    },
    async confirmCancel () {
      const alert = await alertController.create({
        header: 'Are you sure?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
          },
          {
            text: 'Yes',
            role: 'confirm',
          },
        ],
      })

      await alert.present()
      const result = await alert.onDidDismiss()
      if (result.role === 'confirm') {
        this.reset()
        modalController.dismiss(null, 'close')
      }
    }
  }
});
</script>

<style scoped>
.--balanced-padding {
  padding: 0 20px 0 20px;
}

ion-radio {
  width: 30px;
  height: 30px;
}

ion-radio::part(container) {
  border-radius: 8px;
  border: 2px solid #ddd;
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background: #ff6600;
  border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
  width: 6px;
  height: 10px;

  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #fff;

  transform: rotate(45deg);
}
</style>
