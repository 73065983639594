<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Tests</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Tests</ion-title>
        </ion-toolbar>
      </ion-header>

      <video controls>
        <source :src="videoSource">
      </video>

      <ion-list>
        <ion-item
          v-for="(session) in sessions"
          :key="session.id"
        >
          {{ session.id }}
        </ion-item>
      </ion-list>

      <ion-button
        @click="addSession()"
        color="secondary"
      >
        Add Session
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonButton } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { db } from '../data/db'
import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonContent,
    IonTitle,
    IonList,
    IonItem
  },
  setup () {
    return {
      videoSource: '/assets/videos/00_What%20is%20the%20Taxonomy_fc856b36-59de-11ed-a509-5ef6bb69a334.mp4',
      sessions: useObservable(
        liveQuery(() => db.sessions.toArray())
      ),
      addSession () {
        db.sessions.add({})
      }
    }
  },
  mounted () {
    console.log('TestsPage mounted')
    console.log(db.sessions.count())
  },
})
</script>

<style scoped lang="scss">
video {
  width: 100%;
  margin: 1rem 0;
}
</style>