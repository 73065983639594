import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { getCategory } from '../data/taxonomy'

import HomePage from '../views/HomePage.vue'
import TestsPage from '../views/TestsPage.vue'

import TaxonomyHomePage from '../views/taxonomy/HomePage.vue'
import TaxonomySessionHomePage from '../views/taxonomy/SessionHomePage.vue'
import TaxonomyCategoryPage from '../views/taxonomy/CategoryPage.vue'
import TaxonomyProjectPage from '../views/taxonomy/ProjectPage.vue'

import DreamScenarioSurvey from '../views/surveys/DreamScenarioSurvey.vue'

const routes: Array<RouteRecordRaw> = []

routes.push({
  path: `/taxonomy/category/:categoryId`,
  component: TaxonomyCategoryPage,
  name: 'TaxonomyCategory',
  props: route => ({
    category: getCategory(route.params.categoryId.toString())
  })
})

routes.push({
  path: '/taxonomy/session/project',
  name: 'TaxonomyProjectPage',
  component: TaxonomyProjectPage,
  props: true,
})

routes.push({
  path: '/taxonomy/session',
  name: 'TaxonomySessionHome',
  component: TaxonomySessionHomePage,
  props: true,
})

routes.push({
  path: '/taxonomy',
  name: 'TaxonomyHome',
  component: TaxonomyHomePage,
})

routes.push({
  path: '/tests',
  name: 'Tests',
  component: TestsPage,
})

routes.push({
  path: '/',
  name: 'Home',
  component: HomePage,
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
