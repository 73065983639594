<template>
  <div class="plyr-container ion-padding-bottom">
    <video
      playsinline
      controls
      :poster="poster"
    >
      <source
        :src="src"
        type="video/mp4"
      >
    </video>
  </div>
</template>

<script>
import Plyr from 'plyr'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    poster: {
      type: String,
      required: false
    },
    src: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      plyr: null,
    }
  },
  mounted () {
    this.plyr = new Plyr(this.$el.querySelector('video'))
    this.plyr.on('ready', () => this.$emit('ready'))

    this.plyr.on('enterfullscreen', () => {
      document.getElementsByTagName('html')[0].classList.toggle('--plyr-fullscreen', true)
      this.$emit('enterfullscreen')
    })

    this.plyr.on('exitfullscreen', () => {
      document.getElementsByTagName('html')[0].classList.toggle('--plyr-fullscreen', false)
      this.$emit('exitfullscreen')
    })
  },
  setup () {
    //
  },
  methods: {
    play() {
      this.plyr.play()
    },
    pause() {
      this.plyr.pause()
    },
    stop() {
      this.plyr.stop()
    }
  }
})
</script>

<style lang="scss">
html.--plyr-fullscreen {
  ion-footer {
    display: none !important;
  }
  ion-header {
    display: none !important;
  }
}
</style>


