<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
        </ion-buttons>
        <ion-title>Your Mission</ion-title>
        <ion-buttons slot="end">
          <your-report-button color="dark"></your-report-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding">
      <div id="container" class="">

        <h1 class="ion-padding" style="text-align:center;">What are you trying to achieve?</h1>

        <ion-card>
          <ion-card-header>
            <ion-card-title>
              Purpose
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-textarea
              placeholder="What are the desired outcomes? What problems are you addressing?"
              :autogrow="true"
              v-model="$store.state.session.project.purpose"
            ></ion-textarea>
          </ion-card-content>
        </ion-card>

        <h2 class="subheader" style="text-transform: none;">
          Tell us about your constraints.
        </h2>

        <ion-card>
          <ion-card-header>
            <ion-card-title>
              Timeframe
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-range
                    color="secondary"
                    min="1"
                    max="24"
                    :snaps="true"
                    :ticks="false"
                    v-model="timeframe"
                  ></ion-range>
                </ion-col>
                <ion-col
                  size="auto"
                  style="width:33%;"
                >
                  <ion-item>
                    <ion-input
                      style="font-weight: 900;"
                      :readonly="true"
                      :value="timeframeDisplay"
                    ></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header>
            <ion-card-title>
              Scale
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-range
                    color="secondary"
                    min="100"
                    max="10000"
                    step="100"
                    :snaps="true"
                    :ticks="false"
                    v-model="learners"
                  ></ion-range>
                </ion-col>
                <ion-col
                  size="auto"
                  style="width:33%;"
                >
                  <ion-item>
                    <ion-input
                      style="font-weight: 900;"
                      :readonly="true"
                      :value="learnersDisplay"
                    ></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header>
            <ion-card-title>
              Budget
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-range
                    color="secondary"
                    min="10000"
                    max="1000000"
                    step="10000"
                    :snaps="true"
                    :ticks="false"
                    v-model="budget"
                  ></ion-range>
                </ion-col>
                <ion-col
                  size="auto"
                  style="width:33%;"
                >
                  <ion-item>
                    <ion-input
                      style="font-weight: 900;"
                      :readonly="true"
                      :value="budgetDisplay"
                    ></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>

    <taxonomy-toolbar
      :next="true"
      :previous="true"
      @next="onNext"
      @previous="onPrevious"
    ></taxonomy-toolbar>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { useIonRouter, IonItem, IonGrid, IonCol, IonRow, IonInput, IonRange, IonTextarea, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import YourReportButton from '../../components/YourReportButton.vue';
import TaxonomyToolbar from '../../components/TaxonomyToolbar.vue';
import numeral from 'numeral'
import { taxonomy } from '../../data/taxonomy'

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonCard,
    IonRange,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonToolbar,
    IonButtons,
    IonTextarea,
    IonContent,
    IonTitle,
    IonItem,
    IonMenuButton,
    YourReportButton,
    TaxonomyToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
  },
  props: {
    sessionId: {
      type: String,
      default: null,
    }
  },
  mounted () {
    //
  },
  data () {
    const router = useIonRouter()
    return {
      timeframe: this.$store.state.session.project.timeframe,
      learners: this.$store.state.session.project.learners,
      budget: this.$store.state.session.project.budget,
      onNext () {
        router.push({
          name: 'TaxonomyCategory',
          params: {
            categoryId: taxonomy.categories[0].id,
          }
        }, 'forward')
      },
      onPrevious () {
        router.push({
          name: 'TaxonomyHome',
        }, 'back')
      }
    }
  },
  watch: {
    timeframe() {
      this.$store.state.session.project.timeframe = this.timeframe
    },
    learners() {
      this.$store.state.session.project.learners = this.learners
    },
    budget() {
      this.$store.state.session.project.budget = this.budget
    },
  },
  computed: {
    timeframeDisplay() {
      return numeral(this.timeframe).format('0,0') + (this.timeframe === 1 ? ' month' : ' months')
    },
    learnersDisplay() {
      return numeral(this.learners).format('0,0') + (this.learners === 1 ? ' learner' : ' learners')
    },
    budgetDisplay() {
      return numeral(this.budget).format('$0,0')
    }
  }
})
</script>
